import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Avatar, Button, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import './home.sass'
import './event.sass'
import { utils, read, writeFile } from 'xlsx';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useForm, SubmitHandler } from 'react-hook-form';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';


import visitorLogo from '../assets/images/client_not_set.png'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ResponsiveLine } from '@nivo/line'


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import UserCard from '../Components/UserCard/UserCard';

import { apiEvent, apiUser, refreshToken } from '../Components/ApiCall';
import UserCardLoader from '../Components/UserCard/UserCardLoader';
import { linearGradientDef } from '@nivo/core';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SyncSummaryHeader from '../Components/SyncSummaryHeader';
import UserEventCart from '../Components/UserCard/UserEventCard';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

type FormData = {
  id: number;
  document: string;
};

// function createData(
//   tickets: number,
//   cancelled: number,
//   synked: number,
//   unique: number,
//   duplicity: number,
//   last_ticket: string,
//   last_sync: string,
//   sync_waiting: number,
//   sync_error: number,
// ) {
//   return { 
//     tickets,
//     cancelled,
//     synked,
//     unique,
//     duplicity,
//     last_ticket,
//     last_sync,
//     sync_waiting,
//     sync_error,
//    };
// }

const dateFormatter = (dateTime:string, fix = false)=>{

  const options:any = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const oldDate = new Date(dateTime);
  
  if(fix){
    oldDate.setHours(oldDate.getHours() - 3);
  }

  if (isNaN(oldDate.getTime())) {
    return '--';
  }

  return oldDate.toLocaleDateString('pt-BR', options).split(',').join( )
}

const GameSync = () => {
  const { t } = useTranslation();
  // return <h1>{t('hello_world')}</h1>;


  const [value, onChange] = useState<Value>([new Date(), new Date()]);

  const [displayUserProfile, setDisplayUserProfile] = useState(false)
  const [userProfile, setUserProfile] = useState({})




  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const [notFound, setNotFound] = React.useState(false);


  const activateGame  = async (eventData:any) => {


    try {
      const response = await apiEvent.post(`/event/active/list`,
        {
          name: `${eventData.home} vs ${eventData.visitor}`,
          companyName: companydata.name,
          eventsIdlist: eventData.eventsIdlist
        }
      );
      
      alert('Partida Iniciada!')
      window.location.reload()
    }catch(e){
      console.log(e)
    }
  }


  const [duplicatedTickets, setDuplicatedTickets] = useState<any>(null);
  const getDuplicatedTickets  = async (eventId:any) => {


    try {
      const response = await apiEvent.get(`/tickets/vlegacy/duplicated/${eventId}`);

      setDuplicatedTickets(response.data)
    }catch(e){
      console.log(e)
    }
  }

  
  const [syncStatusSummary, setSyncStatusSummary] = React.useState(null);
  const fetchSyncStatusSummary = async () => {

    try {
      const response = await apiEvent.get(`/tickets/v3/summary/all`);

      setSyncStatusSummary(response.data)
  
    } catch (error) {
      console.error(error);
    }
  
  }

  // const fetchSyncSummary = async () => {

  //   try {
  //     const response = await apiEvent.get(`/tickets/v3/summary/all`);

  //     setRows([createData(
  //       (response.data?.total_tickets[0]?.count || 0),
  //       (response.data?.total_tickets[1]?.count || 0),
  //       0,
  //       0,
  //       1554,
  //       (dateFormatter(response.data?.last_ticket?.createdAt) || '- -'),
  //       (dateFormatter(response.data?.last_ticket?.createdAt) || '- -'),
  //       0, 0)])
  
  //   } catch (error) {
  //     console.error(error);
  //   }
  
  // }
  
  const [rows, setRows] = useState<any>([])

  const closeUserSearchBox = () => {
    setUserProfile({})
    setDisplayUserProfile(false)
    setNotFound(false)
  }

  const handleChange = () => {
    console.log('oie')
  }

  const [companydata, setCompanydata] = React.useState({
    name: 'Bepass',
    logo: 'https://storage.googleapis.com/companies-assets/bepass/bepass_logo_default.png'
  });

  const [bannerData, setBannerData] = React.useState({
    eventType: 'game',
    home: 'TBD',
    visitor: 'TBD',
    visitorLogo: visitorLogo,
    eventDate: 'TBD',
    status: 'idle',
    eventsIdlist: [],
    externalId: ''
  });

  const [eventList, setEventList] = useState<any>([])

  const [auditDeviceList, setAuditDeviceList] = useState<any>([])

  const [reportTicketList, setReportTicketList] = useState<any>([])

  const fetchData = async () => {
    try {

      const companyId = sessionStorage.getItem('company_id') ||  'na'
      const response = await apiEvent.get(`/event/group/events/company/${companyId}`);

      const activeEvents = response.data.events.filter((item:any) => item.isActive)
      getEventBannerInfo(activeEvents[0])
      setEventList(activeEvents)
      fetchEventSummary('all')
      fetchAuditSummary()
      fetchTicketsData()
      // fetchSyncSummary()
      fetchSyncStatusSummary()
    getDuplicatedTickets('20250130')
    } catch (error) {
      console.error(error);
    }
  };

  const changeEventSummary = async (eventId:any) => {
    console.log(eventId)
    console.log(eventList)

    const activeEvents = eventList.filter((item:any) => item.id === eventId)

    getEventBannerInfo(activeEvents[0])
    fetchEventSummary('all')
    fetchAuditSummary()
    fetchTicketsData()
    // fetchSyncSummary()
  }

  const getEventBannerInfo = (item:any) => {
    if(!item){
      return false
    }

    const externalId = item.events[0]?.externalId || '';

    setBannerData({
      eventType: 'game',
      home: sessionStorage.getItem('company_slug') || '',
      visitor: item.events[0].eventName.split(' x ')[1] || 'TBD',
      visitorLogo: visitorLogo,
      eventDate: item.events[0].startDateTime,
      status: item.events[0].status,
      eventsIdlist: item.events.map((item:any) => item.id),
      externalId
    })
  }


const [summaryList, setSummaryList] = useState<any>([])

const [summaryData, setSummaryData] = useState({
  ticket_faces: 0,
  tickets_without_faces: 0,
  tickets_canceled: 0,
  total_valid_tickets: 0
})


const [canceledGameTickets, setCanceledGameTickets] = useState<any>(0)

const fetchEventSummary = async (eventId: any) => {

  try {
    const response = await apiEvent.get(`/tickets/vlegacy/summary/${eventId}`);

    let tempResult = {
      ticket_faces: 0,
      tickets_without_faces: 0,
      tickets_canceled: 0,
      total_valid_tickets: 0
    }


    await response.data.map((item:any) => {
      if(item.isCanceled){
        setCanceledGameTickets(item.count)
      }
      if(item.has_face_associated){
        if(item.status === 'active'){
          tempResult.ticket_faces += Number(item.count)
          tempResult.total_valid_tickets += Number(item.count)
        }else{
          tempResult.tickets_canceled += Number(item.count)
        }
      }else{
        if(item.status === 'active'){
          tempResult.tickets_without_faces += Number(item.count)
          tempResult.total_valid_tickets += Number(item.count)
        }else{
          tempResult.tickets_canceled += Number(item.count)
        }
      }
    })



    // setSummaryData(tempResult)

    // setSummaryList([{
    //   tickets: tempResult.total_valid_tickets,
    //   without_faces: tempResult.tickets_without_faces,
    //   cancelled: tempResult.tickets_canceled,
    // }])

    // setTableLoading(false)

  } catch (error) {
    console.error(error);
  }

}

const [syncTotalTickets, setSyncTotalTickets] = useState<any>(0)
const [lastTickeOnSync, setLastTickeOnSync] = useState<any>(null)
const [syncShortSummary, setSyncShortSummary] = useState<any>({
  done: 0,
  waiting: 0,
  failed: 0
})
const fetchAuditSummary = async () => {
  let _temp_counter = 0
  try {
    // todos com mca legacy
    // const response = await apiEvent.get(`/sync/audit/active-events/legacy-tickets/20715`);
    const response = await apiEvent.get(`/sync/audit/active-events`);

    if(response.data.data === 'error'){
      return false
    }
    setAuditDeviceList(response.data.data)
    // const sortedData = response.data.data.sort((a: any, b: any) => a.name.localeCompare(b.name));
    // setAuditDeviceList(sortedData);

    setLastTickeOnSync(response.data.lastTicket)
    for(const item of response.data.data){  
      _temp_counter += Number(item.faceTotal)
    }

    const _temp_shortSync:any = {
      done: 0,
      waiting: 0,
      failed: 0
    }

    for(const syncItem of response.data.totalTicketsOnSync){
      if(syncItem.isFaceCreated === 'notStarted'){
        _temp_shortSync.waiting = syncItem.count
      }else if(syncItem.isFaceCreated === 'failed'){
        _temp_shortSync.failed = syncItem.count
      }else{
        _temp_shortSync.done = syncItem.count
      }
    }


    console.log('-  - -')
    console.log(_temp_shortSync)
    setSyncShortSummary(_temp_shortSync)

    setSyncTotalTickets(_temp_counter)

  } catch (error) {
    console.error(error);
  }

}

const [totalGameTickets, setTotalGameTickets] = useState(false)
const [lastTicketIntegrated, setLastTicketIntegrated] = useState<any>(null)
const fetchTicketsData = async () => {

  try {
    const response = await apiEvent.get(`/tickets/vlegacy/all`);


    setLastTicketIntegrated(response.data[0][0])
    setReportTicketList(response.data[0])
    setTotalGameTickets(response.data[1])
  } catch (error) {
    console.error(error);
  }

}

  const downloadTickets = () => {

    const reportDownload:any = []
    for(const entrance of reportTicketList){
  
      const dateStr = entrance.checkInDate || 0;
      const date = new Date(dateStr);
  
      // Subtract 3 hours (3 hours * 60 minutes/hour * 60 seconds/minute * 1000 milliseconds/second)
      date.setTime(date.getTime() - (6 * 60 * 60 * 1000));
  
      const result = date.toISOString();
  

      reportDownload.push({
        nome: entrance.holderName,
        email: entrance.holderEmail,
        documento: entrance.holderDocument,
        temBiometria: entrance.hasFaceAssociated,
        codigo: entrance.tokens[0]?.token || entrance.tokens?.token,
        eventId: entrance.eventId,
        sessionId: entrance.sessionId,
        checkIn: result,
        CheckInTipo: entrance.checkInType,
        ticket: entrance.ticket
      })
  
    }
  
    const worksheet = utils.json_to_sheet(reportDownload);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'relatorio');
    writeFile(workbook, `ingressos.xlsx`);
  
  }

  
  const downloadContingency = async (externalId: string) => {
    try {
      if (!externalId) {
        console.error('ExternalId está vazio!');
        return;
      }
  
    
      const response = await apiEvent.get(`tickets/${externalId}`);
  

      const data = response.data;
  

      const reportDownload = data.map((entry: any) => ({
        nome: entry.name || 'Nome não encontrado',
        email: entry.email || 'sem email',
        documento: entry.cpf || 'CPF não encontrado',
        ticket: entry.ticket || 'Ticket não encontrado',
        checkIn: entry.logData || 'Data não encontrada',
        motivo: entry.reason || 'Sem motivo registrado',
        //operador: entry.operator || 'sem nome',
      }));
  
      const worksheet = utils.json_to_sheet(reportDownload);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'Relatório');
      writeFile(workbook, `contingencia_${externalId}.xlsx`);
  
      console.log('Relatório gerado com sucesso!');
    } catch (error) {
      console.error('Erro ao gerar o relatório:', error);
    }
  };
  
  const [syncReceived, setSyncReceived] = useState<any>([{
    x: '20:10',
    y: 239
  },{
    x: '20:20',
    y: 716,
  },{
    x: '20:30',
    y: 301,
  },{
    x: '20:40',
    y: 918,
  }])
  const [syncUpdated, setSyncUpdated] = useState<any>([{
    x: '20:10',
    y: 229
  },{
    x: '20:20',
    y: 916,
  },{
    x: '20:30',
    y: 81,
  },{
    x: '20:40',
    y: 1318,
  }])

  const [rolucre, setRolucre] = useState<any>([])
  const fetchSyncData = async () => {

    try {
      const response = await apiEvent.get(`/sync/time/graph`);
  
     const rola:any = [],
     _tempReceived:any = [],
     _tempUpdated:any = []
     
     response.data.map((item:any) => {
        if(item.syncType === 'ticket_updated'){
          _tempUpdated.push({
            "x": dateFormatter(item.syncTime),
            "y": Number(item.totalTickets) || 0
          })
          _tempReceived.push({
            "x": dateFormatter(item.syncTime),
            "y": 0
          })
        }else{
          _tempReceived.push({
            "x": dateFormatter(item.syncTime),
            "y": 0
          })
          _tempUpdated.push({
            "x": dateFormatter(item.syncTime),
            "y": Number(item.totalTickets) || 0
          })
        }
      })

      const legendaryRola = response.data.map((item:any) => {
        if(item.syncType === 'ticket_updated'){
          return dateFormatter(item.totalTickets)
        }
      })
      
      setTimeout(() => {
        // setSyncReceived(_tempReceived)
        // setSyncUpdated(_tempUpdated)
      }, 1000);

    } catch (error) {
      console.error(error);
    }
  
  }

  const setDeviceStatus = (deviceCount:any,refferenceNumber:any) => {
    if(deviceCount === 'offline' || deviceCount === 'terminalInactive'){
      return 'offline'
    }else if(deviceCount === refferenceNumber){
      return 'idle'
    }
    return 'waiting'
  }
  

  let _search_submit_times = 0
  const onSubmit: SubmitHandler<FormData> = async (data) => {

    setSuccess(false);
    setLoading(true);

    setUserProfile({})
    setDisplayUserProfile(false)
    setNotFound(false)
    
      try {
        const response = await apiUser.post('/biometry/find/user/byDocument', 
          {
            userId: data.document.replace(/\./g, '').replace(/-/g, ''),
            companyId: 'c48126a8-a719-48ca-a3a7-e19eaa388da4',
            userSearchType: 'document',
            userType: 'game'
          }
        );

        _search_submit_times = 0
        if(response.data.data && response.data.data.length === 0){

          setNotFound(true)
          setLoading(false);

          setTimeout(() => {
            setNotFound(false)
          }, 3000);
          return false
        }
        setUserProfile(response.data.data[0])
        setDisplayUserProfile(true)

        setSuccess(true);
        setLoading(false);

        setTimeout(() => {
          setSuccess(false);
        }, 2000);
    
      } catch (error) {
        const _temp_can_refresh = await refreshToken()
        if(_search_submit_times > 2){
          console.error(error);
          alert('Sua sessão expirou, por favor faça seu login novamente.')
          return window.location.href = '/'
        }else{
          _search_submit_times++
          if(_temp_can_refresh){
            onSubmit(data)
          }else{
            const _temp_company = sessionStorage.getItem('selected_cmp') || ''
            sessionStorage.clear()
            if(_temp_company){
              sessionStorage.setItem('selected_cmp', _temp_company)
            }
            alert('Sua sessão expirou, por favor faça seu login novamente.')
            return window.location.href = '/'
          }
        }
      }


    console.log('Form data submitted:', data);
  };


  const reloadUserData = (document:any) => {
    onSubmit({
      id: 1,
      document
    })
  }
  
  useEffect(() => {
    fetchData()
    fetchSyncData()
    setCompanydata({
      name: sessionStorage.getItem('company_slug') || '',
      logo: sessionStorage.getItem('company_logo') || ''
    })
  }, [])
  return (



    <Box>

      <Grid className="graph-biometries-counter" container spacing={2}>

        <Grid size={{xs: 12, md: 9}}>
          <Typography variant="h3" gutterBottom>
            Selecione a partida
          </Typography>

{eventList && eventList.length > 0 && (
   <Box className="event-select">
   <FormControl  fullWidth>
           <InputLabel id="demo-simple-select-label">Escolher</InputLabel>
           <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
             // value={age}
             label="Escolher"
             defaultValue={eventList[0].id}
             onChange={(e) => {changeEventSummary(e.target.value)}}
           >
             {eventList && eventList.map((item:any, index:any) => (
               <MenuItem  key={index} value={item.id}>{item.name}</MenuItem>
             ))}
             
           </Select>
         </FormControl>
   </Box>
)}
 
          


          <Box className="event-main-display">

          {bannerData.status === 'active' && (
              <Box 
                className="action-row" 
                onClick={() => downloadContingency(bannerData.externalId)}
              >
                <span>contingência</span>
                <i><InsertChartOutlinedIcon /></i>
              </Box>
            )}


            <Typography variant="h1" noWrap component="h1">
              {sessionStorage.getItem('company_slug') === 'botafogo' && 'Nilton Santos' }
              {sessionStorage.getItem('company_slug') === 'flamengo' && 'Maracanã' }
              {sessionStorage.getItem('company_slug') === 'gremio' && 'Arena Poa ' }
              {sessionStorage.getItem('company_slug') === 'fluminense' && 'Maracanã' } 
              {sessionStorage.getItem('company_slug') === 'dunas' && 'Arena Dunas' } 
              {sessionStorage.getItem('company_slug') === 'palmeiras' && 'Allianz Parque' } 
            • {dateFormatter(bannerData.eventDate)}
            </Typography>

            <Box className="event-card-game">
              <Box className="game-club" sx={{ display: { xs: 'none', md: 'block' }}}>
                <img src={companydata.logo} alt={bannerData.home} />
              </Box>

              <Box className="game-event-details">
                <Typography variant="h3" noWrap component="h3">
                  {bannerData.home}
                </Typography>
                <Typography variant="caption" noWrap component="span">
                 vs
                </Typography>
                <Typography variant="h3" noWrap component="h3">
                {bannerData.visitor}
                </Typography>
              </Box>

              <Box className="game-club" sx={{ display: { xs: 'none', md: 'block' }}}>
                <img src={bannerData.visitorLogo} alt={bannerData.visitor} title={bannerData.visitor} />
              </Box>
            </Box>

      <Box className="button-group">
      <Button variant="contained" color="primary"
      onClick={() => downloadTickets()}
      >
            Baixar tickets
      </Button>
      <Button  disabled={bannerData.status === 'active' ? true:false} variant="contained" color="primary"
      onClick={() => activateGame(bannerData)}>
        iniciar Partida
      </Button>
      </Box>


          </Box>


        </Grid>

        <Grid size={3} sx={{ display: { xs: 'none', md: 'block' }}}>
       {/*    <Typography variant="h3" gutterBottom>
            Data do evento
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
        />
         open={true}
         closeOnSelect={false} */}
    
  {/*   </LocalizationProvider>

         <DesktopDatePicker
          onChange={handleChange}
          /> */}
        </Grid>
      </Grid>



      <Typography variant="subtitle1" sx={{mb: 2}} component="h2">
        Pesquisar sincronização de torcedores
        </Typography>

      <Grid className="user-search"  sx={{mb: 6}} container spacing={2}>
      <Grid  size={{xs: 12, md: 5}}>
<form className='user-search-form' onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth={true} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Pesquisar documento</InputLabel>
          <OutlinedInput
          {...register("document", { required: "Nome é obrigatório!" })}
          error={!!errors.document}
            id="outlined-adornment-password"
            endAdornment={
              <InputAdornment position="end">
                <Button
                type='submit'>
<IconButton
                  // onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  // onMouseUp={handleMouseUpPassword}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
                </Button>
              </InputAdornment>
            }
            label="Pesquisar documento"
          />

        </FormControl>
        {notFound && (
          <Alert severity="info" color="warning">
          Usuário não encontrado
        </Alert>
        )}
        </form>

  </Grid>
  <Grid size={{xs: 12, md: 12}}>
  {loading && (
    <UserCardLoader/>
  )}
  {displayUserProfile && (
    <>
    <UserEventCart userData={userProfile} handleCloseBox={closeUserSearchBox} handleRefresh={reloadUserData} />
    </>
  )}
  </Grid>
</Grid>

    <Box className="event-sync-symmary">
      <Typography variant="h2">
        Resumo
      </Typography>
      
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Ingressos vendidos</TableCell>
            <TableCell>Ingressos cancelados</TableCell>
            <TableCell>Ingressos sincronizados</TableCell>
            <TableCell>CPF em duplicidade</TableCell>
            <TableCell>Último ingresso recebido</TableCell>
            <TableCell>Última sincronização</TableCell>
            <TableCell>Fila de sincronização</TableCell>
            <TableCell>Erro de sincronização</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell>{totalGameTickets}</TableCell>
            <TableCell>{canceledGameTickets}</TableCell>
            <TableCell>{syncShortSummary.done || 0}</TableCell>
            <TableCell>{duplicatedTickets?.length}</TableCell>
            <TableCell>{dateFormatter(lastTicketIntegrated?.createdAt)}</TableCell>
            <TableCell>{dateFormatter(lastTickeOnSync?.updatedAt)}</TableCell>
            <TableCell>{syncShortSummary.waiting || 0}</TableCell>
            <TableCell>{syncShortSummary.failed || 0}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </Box>


    <Box className="sync-summary-header" sx={{mb: 6}}>
    <Typography variant="h2" gutterBottom>
            Dispositivos
          </Typography>
          <ul className="sync-symmary-status">
            <li className='status-idle'>sincronizado</li>
            <li className='status-waiting'>sinc em andamento</li>
            <li className='status-offline'>off-line</li>
          </ul>

          <Box sx={{ display: { xs: 'none', md: 'block' }}}>
          <Button type="submit" variant="contained" color="primary">Atualizar</Button>
          </Box>
    </Box>


        {auditDeviceList.length > 0 && 
        auditDeviceList.map((item:any, index:any) => (
          <Accordion key={index} className='accordion-sync'>
          <AccordionSummary
            expandIcon={null}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Box className="accordion-sync-symmary">
            {item.gateName}
              <ExpandMoreIcon />
            </Box>
            <SyncSummaryHeader syncData={item} />
          </AccordionSummary>
          
          
          <AccordionDetails>
           <ul className="accordion-sync-list">
            {item.devices.length > 0 && 
            item.devices.map((devices:any, index:any) => (
              <li key={index} className={setDeviceStatus(devices.count, item.faceTotal)}>
              <Typography variant="h3" gutterBottom>
                <span>{devices.ip}</span>
                {Number(devices.count) ? devices.count: 'offline'}
              </Typography>
            </li>
            ))}
            {/* {[{
              device: 'D-01PCD', 
              count:'12.100',
              status: 'idle'
            },{
              device: 'D-02T', 
              count:'11.322',
              status: 'waiting'
            },{
              device: 'D-03T', 
              count:'0',
              status: 'offline'
            },{
              device: 'D-04T', 
              count:'0',
              status: 'offline'
            }].map((item, index) => (
               <li key={index} className={item.status}>
               <Typography variant="h3" gutterBottom>
                 <span>{item.device}</span>
                 {item.count}
               </Typography>
             </li>
             ))} */}
            </ul>
          </AccordionDetails>
        </Accordion>
  
  
        ))}



    </Box>
  )
};

export default GameSync;